import React from 'react';
// Import Swiper React components
import { Swiper } from "swiper/react/swiper-react.js";

// import Swiper core and required modules
import SwiperCore, {Autoplay,
    EffectFade,Navigation,Pagination
} from 'swiper';
// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
// import "swiper/modules/effect-fade.scss";   
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

  
  // install Swiper modules
SwiperCore.use([Autoplay,EffectFade,Navigation,Pagination]);

const Slider = (props) => {
    const {navegation, children} = props;
    return ( 
        <>
        <Swiper 
            spaceBetween={30} 
            effect={'fade'} 
            centeredSlides={true} 
            navigation={navegation} 
            autoplay={{
                delay: 4500,
                disableOnInteraction: false,
            }}  
            className="mySwiper">
            {children}
        </Swiper>
        </>
     );
}
 
export default Slider;