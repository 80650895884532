import React from 'react';
import './Arte.scss';

const Arte = (props) => {

    const mostrarImagen = (tipo) =>{
        props.setModalImagen(true)
        props.setUrl(tipo)
    }
    return ( 
        <div id="arte" className="container__arte">
            <div className="container__arte_top">
                <div className="linea-cuadro big"></div>
                <div className="container__arte_top__left wow animate__animated animate__fadeInLeft">
                    <h3>Amenidades Arte</h3>
                    <div>
                        <h2>RUTA</h2>
                        <h1>SENDERO</h1>
                    </div>
                    <p>Libera tu mente en nuestra Ruta Sendero con su instalación Asentamientos, Coops With a View, los pabellones Pathos Loop y “I am not Fine” Staircase, culminando en la Capilla Mío | Nuestro. Pasa un momento de apreciación en la Galería de Arte o visita el Taller del Artista.</p>
                    <a onClick={()=> props.showModal(true)}>COLABORADORES</a>
                </div>
                <div className="container__arte_top__right">
                    <div className='capilla' onClick={()=> mostrarImagen("./assets/imagenes/06.Amenidades/capilla-xrisanto.jpg")}></div>
                    <img className="wow animate__animated animate__fadeIn" src="./assets/imagenes/06.Amenidades/capilla-thum-xrisanto.jpg" alt="" />
                    <div className="titulo" onClick={()=> mostrarImagen("./assets/imagenes/06.Amenidades/capilla-xrisanto.jpg")}>
                        <p>Capilla Mío | Nuestro</p><img src="./assets/imagenes/icon-capilla-xrisanto.svg" alt="" />
                    </div>
                </div>
            </div>
            <div className="container__arte_bottom">
                <div className="container__arte_bottom__left">
                    <div className="linea-cuadro" onClick={()=> mostrarImagen("./assets/imagenes/06.Amenidades/coops-xrisanto.jpg")}></div>
                    <img className="wow animate__animated animate__fadeIn" src="./assets/imagenes/06.Amenidades/coops-thum-xrisanto.jpg" alt="" />
                    <div className="titulo" onClick={()=> mostrarImagen("./assets/imagenes/06.Amenidades/coops-xrisanto.jpg")}>
                        <p>Coops with a view</p><img src="./assets/imagenes/icon-coops-whith-xrisanto.svg" alt="" />
                    </div>
                </div>
                <div className="container__arte_bottom__right">
                    <div className="linea-cuadro" onClick={()=> mostrarImagen("./assets/imagenes/06.Amenidades/fine-xrisanto.jpg")}></div>
                    <img className="wow animate__animated animate__fadeIn" src="./assets/imagenes/06.Amenidades/fine-thum-xrisanto.jpg" alt="" />
                    <div className="titulo" onClick={()=> mostrarImagen("./assets/imagenes/06.Amenidades/fine-xrisanto.jpg")}>
                        <p>I'm not fine</p><img src="./assets/imagenes/icon-i-fine-xrisanto.svg" alt="" />
                    </div>
                </div>
                <div className="container__arte_bottom__left">
                    <div className="linea-cuadro" onClick={()=> mostrarImagen("./assets/imagenes/06.Amenidades/gallinero-estacional.jpg")}></div>
                    <img className="wow animate__animated animate__fadeIn" src="./assets/imagenes/06.Amenidades/gallinero-estacional-thum.jpg" alt="" />
                    <div className="titulo" onClick={()=> mostrarImagen("./assets/imagenes/06.Amenidades/gallinero-estacional.jpg")}>
                        <p>Gallinero estacional</p><img src="./assets/imagenes/icon-gallinero-estacional-xrisanto.svg" alt="" />
                    </div>
                </div>
                <div className="container__arte_bottom__right">
                    <div className="linea-cuadro" onClick={()=> mostrarImagen("./assets/imagenes/06.Amenidades/pathos-lopps.jpg")}></div>
                    <img className="wow animate__animated animate__fadeIn" src="./assets/imagenes/06.Amenidades/pathos-lopps-thum.jpg" alt="" />
                    <div className="titulo" onClick={()=> mostrarImagen("./assets/imagenes/06.Amenidades/pathos-lopps.jpg")}>
                        <p>Pathos loop</p><img src="./assets/imagenes/icon-pathos-loop-xrisanto.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Arte;