import Home from './Pages/Home/Home';
import './app.scss'
function App() {
  return (
    <div className="App">
        <Home/>
    </div>
  );
}

export default App;
