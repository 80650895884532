import React,{useRef} from 'react';
import './Formulario.scss';
import { Formik, Form, Field } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import swal from 'sweetalert';
import axios from 'axios';
import * as Yup from 'yup';
 
const Formulario = () => {
    const captchaRef = useRef(null);

    const SignupSchema = Yup.object().shape({
        nombre: Yup.string().min(2, 'Se espera mínimo 2 dígitos').max(50, 'Se espera máximo 50 dígitos').required('Campo requrido'),
        telefono: Yup.string().min(10,'Se esperan mínimo 10 dígitos').max(10,'Se esperan máximo 10 dígitos').required('Campo requerido'),
        email: Yup.string().email('Correo inválido').required('Campo requerido'),
        recaptcha: Yup.string().required("Llenar captcha")
    });

    return ( 
        <div className="container__brochure">
            <div className="formulario">
                <h3>Brochure</h3>
                <div>
                    <h2>UN NUEVO</h2>
                    <h1>RENACER</h1>
                </div>
                <p>Déjanos tus datos y descarga nuestro brochure</p>
                <Formik
                    initialValues={
                        { 
                            nombre: '', 
                            email: '', 
                            telefono: '',
                            recaptcha: ''
                        }
                    }
                    validationSchema={SignupSchema}
                    onSubmit={ async (values,{ resetForm }) => {
                        console.log("Esto es", values)
                        const response = await axios.post(process.env.REACT_APP_PROTOTYPE_CONTACT_ENDPOINT, values)
                        if(response.data.success){
                            var link = document.createElement('a');
                            link.href = './assets/Brochure_xrisanto.pdf';
                            link.download = 'Brochure.pdf';
                            link.dispatchEvent(new MouseEvent('click'));
                            resetForm();
                            captchaRef.current?.reset()
                            swal("Enviado", "Los datos han sido enviados correctamente", "success");
                        }else{
                            if(response.data.error){
                                swal("Error", "Ocurrio un problema al enviar el correo", "error");
                            }
                        }
                    }}
                    >
                    {({
                        errors,
                        touched,
                        setFieldValue
                    }) => (
                        <Form>
                            <div className="form_row">
                               <div className='field'>
                                    <Field name="nombre" placeholder="*Nombre" type="text"/>
                                        {errors.nombre && touched.nombre ? (
                                            <div className='error'>{errors.nombre}</div>
                                        ) : null}
                               </div>
                               <div className='field'>
                                    <Field name="telefono" placeholder="*Teléfono" type="text"/>
                                        {errors.telefono && touched.telefono ? (
                                            <div className='error'>{errors.telefono}</div>
                                        ) : null}
                               </div>

                            </div>
                            <div className="form_row ">
                                <div className='container-email'>
                                    <Field name="email" type="email" placeholder="Correo Electrónico*"/>
                                        {errors.email && touched.email ? <div className='error'>{errors.email}</div> : null}
                                </div>
                            </div>
                            <div className="descargar">
                                <p>*Al descargar el brochure acepta nuestros términos de privacidad</p>
                                <button type="submit">DESCARGAR</button>
                            </div>
                            <div className="recaptcha">
                                <Field name="recaptcha">
                                    {
                                        ({meta}) => {
                                            const handleChange = (value) => setFieldValue("recaptcha",value)
                                            return (
                                                <>
                                                    
                                                    <ReCAPTCHA
                                                        ref={captchaRef}
                                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                        onChange={handleChange}
                                                    />
                                                    { meta.error && meta.touched ? (
                                                        <div className='error'>{meta.error}</div>
                                                    ) : null}
                                                
                                                </>)
                                        }
                                        
                                    }
                                </Field>
                                <img src="./assets/imagenes/logo-xrisanto-blanco.svg" alt="" />
                            </div>
                               
                        </Form>
                    )}
                    </Formik>
            </div>
        </div>
     );
}
 
export default Formulario;