import React,{useState} from 'react';
import './MasterPlan.scss';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const MasterPlan = () => {
    const [ocultar, setocultar] = useState('');
    const [mostrar, setmostrar] = useState('mostrar');
    const handlemostrar = () =>{
        setocultar('ocultar')
        setmostrar('')
    }
    const handleregresar = () =>{
        setocultar('')
        setmostrar('mostrar')
    }
    return ( 
        <div id="master-plan" className={`container__plano`}>
            <div className={`primer-img ${ocultar}`}>
                <img src="./assets/imagenes/plano-xrisanto-2.jpg" alt="" />
            </div>
            <div className={`segundo-img ${mostrar}`}>
                <TransformWrapper
                    scale={2}
                >
                    <TransformComponent>
                        <img
                        className="image"
                        alt="testImage"
                        src="./assets/imagenes/plano-xrisanto.jpg"
                        />
                    </TransformComponent>
                </TransformWrapper>
            </div>
            <div className={`container__plano_texto ${ocultar}`}>
                <div className="container__plano_texto_desc wow animate__animated animate__fadeIn">
                    <h3>Lotes recidenciales</h3>
                    <div>
                        <h2>TERRENOS</h2>
                        <h1>CAMPRESTES</h1>
                    </div>
                    <p>Pon en marcha tu plan de vida y disfruta de este sorprendente proyecto arquitectónico, adquiriendo uno de sus Terrenos Campestres, desde los 6,000m <sup>2</sup> , en donde podrás construir el hogar de tus sueños para ti y tu familia.</p>
                    <a onClick={()=>handlemostrar()}>VER MASTER PLAN</a>
                </div>
            </div>
            <a className={`button__back ${mostrar}`} onClick={()=>handleregresar()}>VOLVER</a>
        </div>
     );
}
 
export default MasterPlan;