import React, { useEffect, useState } from 'react';
import './Home.scss';
import Header from '../../Components/Header/Header';
import Portada from '../../Components/Portada/Portada';
import Concepto from '../../Components/Concepto/Concepto';
import Entorno from '../../Components/Entorno/Entorno';
import Arquitectura from '../../Components/Arquitectura/Arquitectura';
import MasterPlan from '../../Components/MasterPlan/MasterPlan';
import Arte from '../../Components/Arte/Arte';
import Modal from '../../Components/Modal/Modal';
import Colaboradores from '../../Components/Colaboradores/Colaboradores';
import Ubicacion from '../../Components/Ubicacion/Ubicacion';
import Footer from '../../Components/Footer/Footer';
import Formulario from '../../Components/Formulario/Formulario';
import { CSSTransition } from "react-transition-group";
import 'animate.css';
import WOW from 'wowjs';

const Home = () => {
    const [modal,showModal] = useState(false);
    const [brochure, showBrochure] = useState(false);
    const [modalImagen, setModalImagen] = useState(false);
    const [urlModal, setUrl] = useState('');
    const [isImagen, setIsImagen] = useState('isImagen')
    const [isBrochure, setIsBrochure] = useState('isBrochure')

    const typeModal = {

    }
    useEffect(()=>{
        new WOW.WOW({
            live: false
        }).init();
    },[]); 

    return ( 
        <div className="container">
            <CSSTransition
                in={brochure}
                timeout={200}
                classNames="show"
                unmountOnExit
            >
                <Modal showModal={showBrochure} isImagen={isBrochure}>
                    <Formulario/>
                </Modal>
            </CSSTransition>
            <CSSTransition
                in={modal}
                timeout={200}
                classNames="show"
                unmountOnExit
            >
                <Modal 
                    background ={'background'}
                    showModal= {showModal}
                >
                    <Colaboradores/>
                </Modal>
            </CSSTransition>
            <CSSTransition
                in={modalImagen}
                timeout={200}
                classNames="show"
                unmountOnExit
            >
                <Modal
                showModal={setModalImagen}
                isImagen={isImagen}>
                    <img src={urlModal} alt="" />
                </Modal>
            </CSSTransition>
            <Header showBrochure={showBrochure}/>
            <Portada />
            <Concepto/>
            <Entorno/>
            <Arquitectura/>
            <MasterPlan/>
            <Arte 
                setModalImagen={setModalImagen}
                showModal = {showModal}
                setUrl={setUrl}
                />
            <Ubicacion />
            <Footer />
        </div>
     );
}
 
export default Home;