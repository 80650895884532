import React from 'react';
import './Ubicacion.scss';

const Ubicacion = () => {
    return ( 
        <div id="ubicacion" className="container__ubicacion">
            <div className="container__ubicacion_row">
                <div className="container__ubicacion_row_direccion">
                    <h3>Ubicación</h3>
                    <div className="one-text">
                        <h2>SAN MIGUEL</h2>
                        <h1>DE ALLENDE</h1>
                    </div>
                    <a href="">Agenda tu cita</a>
                    <div className="logo">
                        <img src="./assets/imagenes/logo-xrisanto-blanco.svg" alt="" />
                        <p>Xrisanto Residencial</p>
                    </div>
                    <div className="direccion"> 
                        <p>San Miguel de Allende</p>
                        <p>Plaza Real del Conde, Carr, S.M.A</p>
                        <p>Qro, Km 1, Local Ab 17 Planta Alta</p>
                    </div>
                    <a href="">+52 253 365 1254</a>
                    <div className="mapa">
                        <a target="_blank" href="https://www.waze.com/en/live-map/directions?latlng=20.786068%2C-100.599639"><img src="./assets/imagenes/icon-waze-xrisanto.svg" alt="" /></a>
                        <a target="_blank" href="https://goo.gl/maps/FMuC5CiKNkfkrZhs9"><img src="./assets/imagenes/icon-google-maps-xrisanto.svg" alt="" /></a>
                    </div>
                </div>
                <div className="container__ubicacion_row_mapa">
                        <img src="./assets/imagenes/map-xrisanto.png" alt="" />
                </div>
            </div>
        </div>
     );
}
 
export default Ubicacion;