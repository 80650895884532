import React from 'react';
import './Colaboradores.scss';
import SliderComun from '../Slider/SliderComun';
import { SwiperSlide } from "swiper/react/swiper-react.js";

const Colaboradores = () => {
    return ( 
        <div className="container__modal_card_colaboradores">
            <SliderComun navegation={true}>
                <SwiperSlide>
                    <div className="container__modal_card_colaboradores_slider portada">
                        <div className="colaboradores">
                            <h3>Sendero de Umbrales</h3>
                            <h1>COLABORADORES</h1>
                            <div className="iconos">
                                <img src="./assets/imagenes/icon-obras.svg" alt="" />
                            </div>
                            <div className="text">
                                <p>Los proyectos en Sendero de los Umbrales han sido diseñados por la <span> Dr. Tania López Winkler</span> en colaboración con diversos artistas y Periferia projects:</p>
                            </div>
                            <div>
                                <img src="./assets/imagenes/periferia-logo-modal.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="container__modal_card_colaboradores_slider">
                        <div className="imagen">
                            <img src="./assets/imagenes/07.Colaboradores/tania-lopez- winkler.jpg" alt="" />
                        </div>
                        <div className="descripcion">
                            <h3>Conceptualización y Dirección</h3>
                            <div className="nombre">
                                <h2>DR. TANIA LÓPEZ</h2>
                                <h1>Winkler</h1>
                            </div>
                            <div className="desc">
                                <p>Tania es arquitecta, académica y artista que radica en Londres. Dr. Tania López Winkler es líder del Primer Año de Maestría en Interiores en el departamento de Arquitectura del Royal College of Art. En la educación, Tania introduce su amplia experiencia en arte y diseño arquitectónico para contribuir creativamente a la educación e investigación en el campo de Arquitectura Interior.</p>
                                <p>Su trabajo ha sido exhibido y publicado internacionalmente. En el 2015 recibió el Premio Latin UK Visual Artist of the Year.</p>
                                <p> Su práctica explora el uso del alterego como medio de investigacióna aspectos varios de modernidad y el estudio de “lo normal”a través de diferentes medios y producción artística. A medidaque avanza su investigación sobre “lo normal” su trabajo develael mundo de fantasía y las imperfecciones poéticas embebidasen lo cotidiano. </p>
                                <p>Tania es graduada del ITESM y del Architectural Association School of Architecture en Londres donde recibió su maestría en Vivienda Urbanismo y su doctorado por la tesis: El detective de la Vida Moderna.</p>
                                <p> www.rca.ac.uk/more/staff/tania-lópez-winkler/ </p>
                                <p>www.interiordesignrca.co.uk/y1/tutors</p>
                                <p> www.tanialopezwinkler.com</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="container__modal_card_colaboradores_slider">
                        <div className="imagen">
                            <img src="./assets/imagenes/07.Colaboradores/lassla-esquivel-durand.jpg" alt="" />
                        </div>
                        <div className="descripcion">
                            <h3>Curaduría y Gestión Cultural</h3>
                            <div className="nombre">
                                <h2>LASSLA ESQUIVEL</h2>
                                <h1>DURAND</h1>
                            </div>
                            <div className="desc">
                                <p>Historiadora del arte, curadora e investigadora independiente. Es maestra por la Universidad de Warwick en Historia y Negocios del Mercado de Arte Contemporáneo. Actualmente es candidata a doctor por la Universidad de Leeds. Su experiencia en el mercadode arte y como curadora ha sido en diferentes países como México, Francia, Inglaterra, España, Hungría, Japón, Indonesia, emiratos Árabes entre otros. Ha asesorado a coleccionistas privados en la compra y administración de colecciones, así como a galerías comerciales con estrategias de desarrollo y administración de arte. Se especializa en mercados emergentes, sobre todo en Lationamérica. Sus proyectos curatoriales han sido exhibidos en las regiones de Latinomérica, Europa del Este y Asia-Pacífico, así como en el Reino Unido. Es la directora de Periferia Projects, una plataforma curatorial especializada en mercados emergentes creando conexiones con el Reino Unido y Europa. Es parte de diferentes comités curatoriales como la Sociedad de Coleccionismo en Londres. Su proyecto de investigación más reciente sobre museos privados y la relación con el mercado de arte fue publicado por la prestigiosa editorial académica Routledge en marzo de 2018. Trabaja como académica especialista en mercado de arte y curaduría en Christie ́s Education Londres y el Instituto de Arte y Cultura Superiori en Paris. </p> <p>lassla@periferia-projects.com </p> <p> www.periferia-projects.com</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="container__modal_card_colaboradores_slider">
                        <div className="imagen">
                            <img src="./assets/imagenes/07.Colaboradores/jim-eyre.jpg" alt="" />
                        </div>
                        <div className="descripcion">
                            <h3>Colaboración en COOP WITH A VIEW</h3>
                            <div className="nombre">
                                <h2>JIM</h2>
                                <h1>DURAND</h1>
                            </div>
                            <div className="desc">
                               <p>Jim se formó originalmente como arquitecto de interiores y ahora ejerce como artista y curador de Spaceless Gallery. En el camino, ha sido propietario de un negocio, diseñador de muebles con Barber Osgerby, cineasta representado por Partizan, director creativo de CP + B, y un diseñador creativo de Sir Paul Smith. Jim cree que la idea siempre debe ser lo primero, luego ser ejecutada en los medios adecuados.</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="container__modal_card_colaboradores_slider">
                        <div className="imagen">
                            <img src="./assets/imagenes/07.Colaboradores/ran-dan.jpg" alt="" />
                        </div>
                        <div className="descripcion">
                            <h3>Colaboración en I AM (NOT) FINE STAIR</h3>
                            <div className="nombre">
                                <h2>RAN</h2>
                                <h1>DUAN</h1>
                            </div>
                            <div className="desc">
                               <p>Ran Duan es una diseñadora originaria de China y graduada del Tecnológico de Harbin. Su experiencia en diseño abarca proyectos de hoteles y comercios, incluida la planeación de tiendas para la marca de lujo Yves Saint Laurent en China.  </p> 
                               <p> Las visiones de Ran a menudo se transmiten a través de narrativas estimulantes y cuidadosamente seleccionadas. Actualmente asiste la Maestría en Diseño de Interiores en el prestigiado Royal College of Art.</p>
                               
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="container__modal_card_colaboradores_slider">
                        <div className="imagen">
                            <img src="./assets/imagenes/07.Colaboradores/chaerim-shin.jpg" alt="" />
                        </div>
                        <div className="descripcion">
                            <h3>Colaboración en I AM (NOT) FINE STAIR</h3>
                            <div className="nombre">
                                <h2>CHAERIM </h2>
                                <h1>SHIM </h1>
                            </div>
                            <div className="desc">
                               <p>Chaerim Shim es diseñadora de interiores originaria de Corea del Sur y radicada en Londres.</p> 
                               <p> Su experiencia  n el mundo de diseño inició en el 2012 al titularse como diseñadora de interiores con licenciatura en bellas artes de la Escuela de Artes Visuales de Nueva York, EE. UU. Su interés en el diseño se enfoca  en diseño de mobiliario, arquitectura y artes. Actualmente asiste la Maestría en Diseño de Interiores en el prestigiado Royal College of Art (2021-2022). </p>
                               
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="container__modal_card_colaboradores_slider">
                        <div className="imagen">
                            <img src="./assets/imagenes/07.Colaboradores/francesca-morris.jpg" alt="" />
                        </div>
                        <div className="descripcion">
                            <h3>Colaboración en I AM (NOT) FINE STAIR</h3>
                            <div className="nombre">
                                <h2>FRANCESCA </h2>
                                <h1>MORRIS</h1>
                            </div>
                            <div className="desc">
                               <p>Francesa es una italo-australiana con experiencia en ingeniería estructural de Brisbane, Australia, y que emigró al Reino Unido en 2018.</p> 
                               <p>Francesca completó sus estudio en la Universidad de Queensland, Australiar recibiendo con honores el grado de ingeniería, así como una licenciatura en comercio. Ha trabajado en la industria de la ingeniería civil para Rober Bird Group en Australia y el Reino Unido en proyectos comerciales y residenciales a gran escala. </p> 
                                <p>En 2019 Francesca predefinió su carrera al volver a estudiar el Diplomado de Posgrado en Arte y Diseño en el Royal college of Art. Actualmente asiste la Maestría en Diseño de Interiores en el prestigiado Royal College of Art (2021-2022).</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </SliderComun>
        </div>
     );
}
 
export default Colaboradores;