import React, { useState } from 'react';
import './Header.scss';

const Header = (props) => {

    const [open, setOpen] = useState('');

    const handleMenu = () =>{
        setOpen(open === 'menu-open' ? '' : 'menu-open')
    }
    return ( 
        <>
        <div className={`container__header ${open}`}>
            <div className="header">
                <p className="btn-toggle" onClick={()=> handleMenu()}><span></span></p>
                <div className="places">
                    <p>XALPA</p>
                    <p>SAN MIGUEL DE ALLENDE</p>
                    <p>GUANAJUATO</p>
                </div>
                <div className="links">
                    <p onClick={()=> props.showBrochure(true)}>BROCHURE</p>
                    <span></span>
                    <a href="#master-plan"><p>DISPONIBILIDAD</p></a>
                </div>
            </div>
            {/* <img src="" alt="" /> */}
        </div>
        <div className={`menu ${open}`}>
            <ul>
                <li><a href="#concepto" onClick={()=> handleMenu()}>CONCEPTO</a></li>
                <li><a href="#entorno" onClick={()=> handleMenu()}>ENOTRNO</a></li>
                <li><a href="#arquitectura" onClick={()=> handleMenu()}>ARQUITECTURA</a></li>
                <li><a href="#master-plan" onClick={()=> handleMenu()}>MASTER PLAN</a></li>
                <li><a href="#arte" onClick={()=> handleMenu()}>ARTE</a></li>
                <li><a href="#ubicacion" onClick={()=> handleMenu()}>UBICACIÓN</a></li>
            </ul>
            <div className="logo-periferia">
                <a target="_blank" rel="noreferrer" href="https://www.periferia-projects.com"><img src="./assets/imagenes/periferia-logo-menu.svg" alt="" /></a>
            </div>
            <img className="mascara" src="./assets/imagenes/logo-mascarara-xrisanto.svg" alt="" />
         </div>
        </>
     );
}
 
export default Header;