import React from 'react';
import './Portada.scss';
import SliderComun from '../Slider/SliderComun';
// Import Swiper React components
import { SwiperSlide } from "swiper/react/swiper-react.js";
// Import Swiper styles
// import 'animate.css';

const Portada = () => {
    return ( 
        <div id="home" className="container__portada">
                <div className="fondo_negro"></div>
                <div className="container__portada_slogan animate animate__animated animate__fadeIn animate__slower">
                    <img src="./assets/imagenes/logo-xrisanto.svg" alt="" />
                </div>
                <SliderComun navegation={false}>
                    <SwiperSlide>
                        <div className="container__portada_slider">
                            <img src="./assets/imagenes/01.Hero/presa-xrisanto.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container__portada_slider">
                            <img src="./assets/imagenes/01.Hero/vereda-xrisanto.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container__portada_slider">
                            <img src="./assets/imagenes/01.Hero/acceso-xrisanto.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                </SliderComun>
                <div className="container__portada_social">
                    <a target="_blank" href="https://www.facebook.com/Xrisanto"><img src="./assets/imagenes/icon-facebook.svg" alt="" /></a>
                    <a target="_blank" href="https://www.instagram.com/xrisanto_mx/"><img src="./assets/imagenes/icon-instagram.svg" alt="" /></a>
                    <a href="#ubicacion"><img src="./assets/imagenes/icon-map-marker.svg" alt="" /></a>
                    <a target="_blank" href="https://wa.me/4424672447"><img src="./assets/imagenes/icon-whatsapp.svg" alt="" /></a>
                </div>
            <div className="container__portada_linea"></div>
        </div>
     );
}
 
export default Portada;