import React from 'react';
import './Arquitectura.scss';
import Slider from '../Slider/Slider';
// Import Swiper React components
import { SwiperSlide } from "swiper/react/swiper-react.js";

const Arquitectura = () => {
    return ( 
        <div id="arquitectura" className="container__arquitectura">
            <div className="container__arquitectura_top">
                <div className="container__arquitectura_top_left wow animate__animated animate__fadeInLeft">
                    <h3>Arquitectura</h3>
                    <h2>ALEJADO DE LA</h2>
                    <h1>COTIDIANIDAD</h1>
                </div>
                <div className="container__arquitectura_top_right wow animate__animated animate__fadeInRight">
                    <p>Xrisanto Residencial Campestre Ofrece la oportunidad de vivir en un espacio grande, rodeado de naturaleza y arte, en un entorno arquitectónico que se adapta a las vistas naturales, ofreciendo sorprendentes paisajes alejados de la cotidianidad.</p>
                </div>
            </div>
            <div className="container__arquitectura_bottom">
                <Slider  navegation={true}>
                    <SwiperSlide>
                        <div className="container__arquitectura_bottom_slider">
                            <img src="./assets/imagenes/04.arquitectura/acceso-thum-xrisanto.jpg" alt="" />
                        </div>
                        <div className="linea-cuadro" data-wow-delay="1s"></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container__arquitectura_bottom_slider">
                            <img src="./assets/imagenes/04.arquitectura/residencia-modelo-thum-xrisanto.jpg" alt="" />
                        </div>
                        <div className="linea-cuadro" data-wow-delay="1s"></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container__arquitectura_bottom_slider">
                            <img src="./assets/imagenes/04.arquitectura/taller-del-artista-thum-xrisanto.jpg" alt="" />
                        </div>
                        <div className="linea-cuadro" data-wow-delay="1s"></div>
                    </SwiperSlide>
                </Slider>
            </div>
        </div>
     );
}
 
export default Arquitectura;