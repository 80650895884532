import React from 'react';
import './Footer.scss'

const Footer = () => {
    const today = new Date();
    const year = today.getFullYear();

    return ( 
        <div className="container__footer">
            <div className="container__footer_left">
                <a target="_blank" href="https://www.periferia-projects.com" rel="noreferrer"><img src="./assets/imagenes/periferia-logo-footer.svg" alt="" /></a>
            </div>
            <div className="container__footer_center">
                <img src="./assets/imagenes/icon-xrisanto-footer.svg" alt="" />
                <div className="copy">
                   <p> Copyright &copy; {year} Periferia Projects | Politica de privacidad</p>
                </div>
                <p>El vendedor de Xrisanto se reserva el derecho de realizar modificaciones en los planos, diseños exteriores, precios, materiales, especificaciones, acabados y características estándar en cualquier momento y sin previo aviso. Las fotografías, las representaciones son ilustrativas y conceptuales.</p>
            </div>
            <div className="container__footer_right">
                <div>
                    <p>Desarrollo de sitio web:</p><a target="_blank" href="https://renderinc.mx/" rel="noreferrer"><img src="./assets/imagenes/Renderinc.svg" alt="" /></a>
                </div>
            </div>
        </div>
     );
}
 
export default Footer;