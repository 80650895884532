import React from 'react';
import './Concepto.scss';
import Slider from '../Slider/Slider';
// Import Swiper React components

import { SwiperSlide } from "swiper/react/swiper-react.js";

const Concepto = () => {

    return ( 
        <div id="concepto" className="container__concepto">
            <div className="container__concepto_left ">
                <Slider navegation={true}>
                    <SwiperSlide>
                        <div className="container__concepto_slider">
                            <img src="./assets/imagenes/02.Concepto/aereo-xrisanto-1.jpg" alt="" />
                        </div>
                        <div className="linea-cuadro" data-wow-delay="1s"></div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="container__concepto_left_slider">
                            <img src="./assets/imagenes/02.Concepto/aereo-xrisanto-2.jpg" alt="" />
                        </div>
                        <div className="linea-cuadro" data-wow-delay="1s"></div>
                    </SwiperSlide>
                </Slider>
            </div>
            <div className="container__concepto_right">
                <img src="./assets/imagenes/logo-mascara-crisanto.svg" alt=""/>
                <div className="container__concepto_right__text wow animate__animated animate__fadeInRight">
                    <h3>Concepto</h3>
                    <div className="one-text">
                        <h2>UN NUEVO</h2>
                        <h1>RENACER</h1>
                    </div>
                    <p>La actualidad exige una transformación, adaptación y renovación. Un nuevo renacer, 
                    que nos permite adaptar nuestro entorno a una vida alejada de las rutinas cotidianas, el estrés y la contaminación, en la 
                    búsqueda por el crecimiento personal y conexión con la naturaleza.</p>
                    <a href="#master-plan">VER MASTER PLAN</a>
                </div>
            </div>
        </div>
     );
}
 
export default Concepto;