import React, { useState } from 'react';
import './Entorno.scss';
const Entorno = () => {
    const [ubicaciones, setUbicacion] = useState('sanmiguel');
    const [activesm, setActivesm] = useState('active');
    const [activeqr, setActiveqr] = useState('');

    const type = {
        img: {
            sanmiguel : './assets/imagenes/03.Entorno/san-miguel-de-allende-xrisanto.jpg',
            queretaro: './assets/imagenes/03.Entorno/queretaro-xrisanto.jpg'
        },
        texto: {
            sanmiguel : 'San Miguel de Allende sin duda la ciudad más internacional y cosmopolita del bajío y uno de los destinos residenciales con mayor demanda en México, tanto para nacionales, como para extranjeros. Lo que ofrece esta ciudad, comparada con otros destinos de México, es una gran plusvalía que, además, las proyecciones estipulan que se mantendrá por los próximos diez años.',
            queretaro: 'Querétaro, una ciudad con una inmensa riqueza histórica y una impresionante proyección hacía el futuro.'
        }
    }
    const changeUbi = (tipo) => {
        if(tipo === 'sm'){
            setUbicacion('sanmiguel')
            setActivesm('active')
            setActiveqr('')
        }else{
            setUbicacion('queretaro')
            setActivesm('')
            setActiveqr('active')
        }

    }
    return ( 
        <div id="entorno" className="container__entorno">
            <div className="fondo_negro"></div>
                <div className="container__entorno_city">
                    <img src={ubicaciones === "sanmiguel" ? type.img.sanmiguel : type.img.queretaro} alt="" />
                    <div className="container__entorno_city_texto">
                        <img src="./assets/imagenes/tipo-logo-xrisanto.svg" alt="logo" />
                        <div className="container__entorno_city_texto_desc">
                            <h3>Entorno</h3>
                           {ubicaciones === "sanmiguel" ? <p>{type.texto.sanmiguel}</p> : <p>{type.texto.queretaro}</p>}
                            <div className="container__entorno_city_texto_desc_button">
                                <a href="#ubicacion">UBICACIÓN</a>
                            </div>
                        </div>
                    </div>
                    <div className="link__ubi"><p className={activesm} onClick={()=>changeUbi('sm')}>SAN MIGUEL DE ALLENDE</p>  <p className={activeqr} onClick={()=>changeUbi('qr')}>QUERÉTARO</p></div>
            </div>
            
        </div>
     );
}
 
export default Entorno;